import React, {useEffect, useState} from 'react';
import { MaterialIcons, FontAwesome, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import * as WebBrowser from 'expo-web-browser';

import Map from "../components/Map"
import TenderModel from "../models/TenderModel";
import apiClient from "../utils/apiClient";
import {Provider as PaperProvider} from "react-native-paper";
import {DatePickerModal} from "react-native-paper-dates";
import datePickerTheme from "../theme/datePickerTheme";
import TableConverter from "../utils/TableConverter";

import { Table, TableWrapper, Row, Rows, Cell, Col } from 'react-native-table-component-2';
import {
  View, Text, Icon, Avatar, Center, ScrollView, Pressable, Divider,
  Input, Button, Modal, FormControl, HStack, useColorMode, Spinner
} from "native-base";
import {Platform, StyleSheet, TouchableOpacity, Share} from "react-native";
import {AuthContext} from "../utils/context/auth";
import AuthUserManager from "../utils/AuthUserManager";
import TokenManager from "../utils/TokenManager";
import {formatBytes} from "../utils/StringTool";
import {localInfo, translate} from "../utils/Translate";
import TenderSkeleton from "../components/skeleton/TenderSkeleton";
import TimeFormatter from "../utils/TimeFormatter";
import { useNavigation } from '@react-navigation/native';
import theme from "../constants/tab/Theme";
import {UNIVERSAL_LINK_PREFIX} from "../environment"
import * as SplashScreen from "expo-splash-screen";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}

const SectionTitle = ({ title, suffix }) => {
  return <View>
    <HStack alignContent="center">
      <Text textAlign="left" fontSize={28} fontWeight={500}>
        {title}
      </Text>
      {
        !!suffix ? <Text textAlign="left" ml={2} mt={2} fontSize={18} fontWeight={400}>
          ({suffix})
        </Text> : null
      }
    </HStack>
    <View height={1} backgroundColor="appPrimary" w={60}/>

  </View>
}

const widthArr = [150, 150, 150, 150, 150]
const isWebPlatform = Platform.OS === 'web';

export default ({ route, navigation }) => {

  const { tenderId, previewData } = route.params;

  const { authUser, isCompany } = React.useContext(AuthContext);

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: `${UNIVERSAL_LINK_PREFIX}app/tender/${tenderId}`
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (!isWebPlatform){
      navigation.setOptions({
        headerRight: () => (
          <Button
            rounded={40} py={1} px={2} leftIcon={<Icon as={<FontAwesome name="share-square-o" />} size={5} />}
            onPress={onShare}
          >
            <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
              {translate('screens.tender.share')}
            </Text>
          </Button>
        ),
      });
    }

  }, [navigation]);


  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmOfferModal, setShowConfirmOfferModal] = useState(false);
  const [isOffering, setIsOffering] = useState(false);
  const [tender, setTender] = useState(TenderModel[0]);
  const [pointsTableData, setPointsTableData] = useState([]);
  const [priceTableData, setPriceTableData] = useState([]);
  const [totalTableData, setTotalTableData] = useState([ [0], [0], [0] ]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showPublishError, setShowPublishError] = useState(false);

  const pointsTableHead = [
    translate('screens.tender.pointsTableHeader.item'),
    translate('screens.tender.pointsTableHeader.requirement'),
    translate('screens.tender.pointsTableHeader.points'),
    translate('screens.tender.pointsTableHeader.offer'),
    translate('screens.tender.pointsTableHeader.total')
  ]

  const priceTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity'),
    translate('screens.tender.priceTableHeader.price'),
    translate('screens.tender.priceTableHeader.total')
  ]

  const [totalTableTitles, setTotalTableTitles] = useState([
    translate('screens.tender.totalTableTitles.TOTAL_HT'),
    translate('screens.tender.totalTableTitles.TVA'),
    translate('screens.tender.totalTableTitles.TOTAL_TTC')
  ]);

  // Offer state
  const [date, setDate] = useState();
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [hasReviewSteps, setHasReviewSteps] = React.useState(false);
  const [offerData, setOfferData] = useState({
    offer: {
      bid_amount : null,
      delivery_date : null,
      reason : null
    },
    offerPointsTables: [],
    offerPriceTables: []
  });

  useEffect(() => {
    (async () => {
      await SplashScreen.hideAsync();

      let auth      = await AuthUserManager.get()
      let authToken = await TokenManager.get()
      setCurrentUser(auth)
      setToken(authToken)
    })();

    let canOffer = !previewData || !tender.is_owner || !tender.has_participate;
    setIsOffering(canOffer)

    getTender()
  }, []);

  function getTender(){

    if (!!previewData) {
      let preview = {...previewData};

      let previewTender = {...preview.tender}
      delete preview.tender;
      let previewTenderData = {...previewTender, ...preview}

      setTVA(previewTenderData.tva)
      setTender(previewTenderData)
      setHasReviewSteps(!!previewData.reviewSteps.length)

      // Set preview tables
      if (preview.pointTablePreview.length > 0){
        let pointTablePreview = preview.pointTablePreview.filter((item, index) => index !== preview.pointTablePreview.length -1 )
        setPointsTableData(pointTablePreview);
      }
      if (preview.priceTablePreview.length > 0){
        let priceTablePreview = preview.priceTablePreview.filter((item, index) => index !== preview.priceTablePreview.length -1 )
        setPriceTableData(priceTablePreview);
      }

      return;
    }

    apiClient.get(`tenders/${tenderId}`).then( response => {
      setTVA(response.data.tva)
      setHasReviewSteps(!!response.data.reviewsteps.length)
      setTender(response.data)
      if (response.data.pointtables.length > 0){
        getTenderPointsTable(response.data.pointtables)
      }
      if (response.data.pricetables.length > 0){
        getTenderPriceTable(response.data.pricetables)
      }
    }).catch(error => console.log(error));
  }

  function getTenderPointsTable(pointTables){
    let tableArr = TableConverter.pointsToArray(pointTables)
    setPointsTableData(tableArr);
  }

  function getTenderPriceTable(priceTables){
    let tableArr = TableConverter.priceToArray(priceTables)
    setPriceTableData(tableArr);
  }

  const handlePublishTender = () => {
    setIsPublishing(true)
    setShowPublishError(false)
    const data = getTenderData(previewData);

    apiClient.post('tenders', data, {isFormData: true}).then(() => {
      setShowConfirmModal(false);
      setIsPublishing(false)
      navigation.navigate('TenderPublished')
    }).catch((error) => {
      setShowPublishError(true)
      setIsPublishing(false)
      console.log(error.response.data)
    })
  }

  function getTenderData(data){
    const tenderData = new FormData();
    tenderData.append('tender', JSON.stringify(data.tender))

    if (data.pointTables.length > 0){
      tenderData.append('pointTables', JSON.stringify(data.pointTables))
    }
    if (data.priceTables.length > 0){
      tenderData.append('priceTables', JSON.stringify(data.priceTables))
    }
    if (data.reviewSteps.length > 0){
      tenderData.append('reviewSteps', JSON.stringify(data.reviewSteps))
    }
    if (data.requiredDocuments.length > 0){
      tenderData.append('requiredDocuments', JSON.stringify(data.requiredDocuments))
    }
    if (data.attachments.length > 0 && Platform.OS !== "web"){
      for (let n = 0; n < data.attachments.length; n++){
        tenderData.append('files[]', data.attachments[n])
      }
    }
    if (Platform.OS === "web" && data.webAttachments.length > 0){
      for (let n = 0; n < data.webAttachments.length; n++){
        tenderData.append('files[]', data.webAttachments[n])
      }
    }

    return tenderData;
  }

  const handleSubmitOffer = () => {
    if (tender.bid_initial_price !== null){
      setShowConfirmOfferModal(true)
      return;
    }
    if (pointsTableData.length === 0){
      setShowConfirmOfferModal(true)
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: tender.tenderable.avatar,
      tenderId,
      isPaidTender: false,
      price: tender.participation_cost,
      offerData: {
        ...offerData,
        offerPointsTables: tender.pointtables,
        offerPriceTables: tender.pricetables,
      }
    })
  }

  const submitOffer = () => {
    setIsPublishing(true)

    const data = new FormData()
    data.append('data', JSON.stringify({
      ...offerData,
      offerPointsTables: tender.pointtables,
      offerPriceTables: tender.pricetables,
      isPaid: 0
    }))

    apiClient.post(`tenders/${tenderId}/offers`, data, {isFormData: true}).then(() => {
      setShowConfirmOfferModal(false);
      setIsPublishing(false)

      navigation.navigate('OfferSent')
    }).catch((error) => console.log(error.response.data))
  }

  function saveDownloadHistory(attachmentId){
    return apiClient.post(`downloads`, {attachmentId})
  }

  const handleBid = (value) => {
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        bid_amount: value.replace(regex, '')
      }
    })
  }

  const onDismissSingle = () => {
    setOpenDatePicker(false);
  };

  const onConfirmSingle = (params) => {
    setOpenDatePicker(false);
    setDate(params.date);

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        delivery_date: getFormattedDate(params.date)
      }
    })
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  const OfferInput = (index, type) => (
    <View>
      <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} keyboardType="numeric" onChangeText={(value) => {
        let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

        let offerValue = value.replace(regex, '');

        if (type === 'point'){
          tender.pointtables[index].offer = offerValue;
          tender.pointtables[index].total = offerValue * tender.pointtables[index].points;

          let updatePointsTableData = [...pointsTableData];
          updatePointsTableData[index][4] = tender.pointtables[index].total;
          setPointsTableData(updatePointsTableData);
        }
        if (type === 'price'){
          tender.pricetables[index].price = offerValue;
          tender.pricetables[index].total = offerValue * tender.pricetables[index].quantity;

          let updatePriceTableData = [...priceTableData];
          updatePriceTableData[index][4] = tender.pricetables[index].total;
          setPriceTableData(updatePriceTableData);
          calculatePriceTable()
        }
      }} textAlign='center' size="2xl" placeholder="- -"/>
    </View>
  );

  function setTVA(tva){
    let updateTVA = [...totalTableTitles];
    updateTVA[1] = `TVA ${tva}%`;
    setTotalTableTitles(updateTVA);
  }

  function calculatePriceTable(){

    let totalHT  = tender.pricetables.reduce((total, item) => item.total + total,0);
    let TVA      = totalHT * tender.tva/100;
    let totalTTC = totalHT + TVA;

    let updatePriceTotal = [...totalTableData];
    updatePriceTotal[0][0] = formatter.format(totalHT);
    updatePriceTotal[1][0] = formatter.format(TVA);
    updatePriceTotal[2][0] = formatter.format(totalTTC);
    setTotalTableData(updatePriceTotal);
  }

  let formatter = new Intl.NumberFormat(localInfo.local, {
    style: 'currency',
    currency: !!tender.currency_id ? tender.currency.code.en : 'DZD',
  });

  function isParticularTender(){
    return tender.tenderable_type === 'App\\Models\\User'
  }


  const handleSubmitPaidOffer = () => {

    if (pointsTableData.length === 0){
      navigation.navigate("PaymentMethods", {
        price: tender.participation_cost,
        tenderId: tenderId,
        offerData: {
          ...offerData,
          offerPointsTables: tender.pointtables,
          offerPriceTables: tender.pricetables,
        }
      })
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: tender.tenderable.avatar,
      tenderId,
      isPaidTender: true,
      price: tender.participation_cost,
      offerData: {
        ...offerData,
        offerPointsTables: tender.pointtables,
        offerPriceTables: tender.pricetables,
      }
    })
  }

  function goToUserProfile(){
    if (!!previewData) return;
    navigation.navigate('UserProfile', {
      profileId: tender.tenderable_id,
      profileType: tender.tenderable_type === "App\\Models\\Company" ? 'company' : 'user'
    })
  }

  if (!tender.title){
    return (
      <TenderSkeleton/>
    )
  }

  function getTenderableName(){
    return tender.tenderable_type === "App\\Models\\Company"
      ? tender.tenderable.name : tender.tenderable.full_name
  }

  return (
    <View
      bg={isDark ? "coolGray.800" : "white"}
      px={3} h="100%"
    >
      {
        !previewData ? null : <View bg="appSecondary" py={1} px={10}>
          <Center>
            <View flexDirection="row">
              <Text ml={4} textAlign="center" fontWeight={500} fontSize={24} color="primary.50">
                {translate('screens.tender.previewText')}
              </Text>
              <Center ml={6}>
                <Icon color="primary.50" as={<Feather name="eye" />} size={7} />
              </Center>
            </View>
          </Center>
        </View>
      }

      <ScrollView>

        <View flexDirection="row" mt={7}>

          <View>
            <Pressable
              onPress={() => {
                if (getTenderableName() === '[deleted]') return;
                goToUserProfile()
              }}
            >
              <Avatar
                mt={2}
                size="xl"
                source={{ uri : tender.tenderable.avatar }}
              />
            </Pressable>
          </View>

          <View flex={1} ml={4} pt={2} >
            <TouchableOpacity
              disabled={getTenderableName() === '[deleted]'}
              onPress={() => {
                goToUserProfile()
              }}
            >
              <Text p={2} textAlign="left" fontSize={18} fontWeight={500} >
                {
                  getTenderableName() !== "[deleted]" ? getTenderableName() : translate('misc.deletedUser')
                } {' '}
                  <Icon
                    p={2}
                    as={MaterialIcons}
                    size="4"
                    name="check-circle"
                    color="appPrimary"
                  />
              </Text>
            </TouchableOpacity>

            {
              getTenderableName() !== '[deleted]' ? (
                  <View flexDirection="row" mt={3}>
                    <Button
                      mr={2} leftIcon={<Icon as={<Feather name="briefcase" />} size={5} />}
                      onPress={() => {
                        goToUserProfile()
                      }}
                    >
                      <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
                        {translate('screens.tender.profile')}
                      </Text>
                    </Button>

                    {
                      !tender.is_owner && !!tender.tenderable.text_me ? (
                        <Button
                          mr={2}
                          leftIcon={<Icon as={<MaterialCommunityIcons name="chat-processing-outline" />} size={5} />}
                          onPress={() => {
                            navigation.navigate('Chat', {
                              type: tender.tenderable_type === "App\\Models\\Company" ? 'companies' : 'users',
                              typeId: tender.tenderable_id,
                              currentSenderId: !!currentUser.company_id ? currentUser.company_id : currentUser.id,
                              token: token
                            })
                          }}
                        >
                          <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
                            {translate('screens.tender.message')}
                          </Text>
                        </Button>
                      ) : null
                    }

                  </View>
                ) : null
            }

          </View>
        </View>

        <View my={2}/>
          <Divider thickness={1}/>
        <View my={2}/>

        <Center
          flexDirection="row" mt={3}
          justifyContent={isParticularTender() || isWebPlatform || !hasReviewSteps ? "flex-start" : "space-evenly"}
        >

          {
            !!previewData && !isCompany || isParticularTender() || !hasReviewSteps ? null : (
              <Button
                mr={2} leftIcon={<Icon as={<MaterialCommunityIcons name="file-document" />} size={4} />}
                onPress={() => navigation.navigate('ReviewStepsPreview', {steps: !!previewData ? tender.reviewSteps : tender.reviewsteps})}
              >
                <Text textAlign="left" color="primary.50" fontSize={17} fontWeight={600}>
                  {translate('screens.tender.reviewSteps')}
                </Text>
              </Button>
            )
          }

            <Button
              mr={2} leftIcon={<Icon as={<MaterialIcons name="email" />} size={4} />}
              onPress={() => {
                if (!!previewData) return;
                navigation.navigate('ReceivedOffers', {tenderId, requiredPoints: tender.required_points})
              }}
            >
              <Text textAlign="left" color="primary.50" fontSize={17} fontWeight={600}>
                {translate('screens.tender.receivedOffers')}
              </Text>
            </Button>

        </Center>
        {
          !!tender.participation_cost === false ? null : <View
            py={3} mt={6} borderColor="appSecondary"
            borderWidth={1} borderRadius={10}
          >
            <Center flexDirection="row" justifyContent="space-evenly">
              <Icon mr={2} color="appSecondary" as={<MaterialIcons name="payment"/>} size={8}/>
              <Text textAlign="left" fontSize={18} fontWeight={500} color="appSecondary">
                {translate('screens.tender.participationFees')} : {tender.participation_cost} {translate('misc.currency')}
              </Text>
            </Center>
          </View>
        }

        <View mt={10}>
          <Title
            title={tender.title} />
        </View>

        <View mt={10} flexDirection="row" justifyContent="space-between">
          <SectionTitle title={translate('screens.tender.description')} />

          <Center h={9} px={2} flexDirection="row" justifyContent="space-between" bg="appAccent" borderRadius={8}>
            <Icon color="primary.50" as={<Feather name="eye" />} size={5} />
            <Text textAlign="left" fontWeight={500} color="primary.50" mx={1}>
              {tender.views_count}
            </Text>
            <Text textAlign="left" fontWeight={500} color="primary.50">
              {translate('screens.tender.views')}
            </Text>
          </Center>
        </View>

        <HStack mt={4}>

          <View maxW="60%" mt={1} px={2} py={1} bg="appAccent" borderRadius={20} alignSelf="flex-start">
            <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
              {tender.branch.name[localInfo.local]}
            </Text>
          </View>

          <HStack w="40%" alignItems="center" justifyContent="center">
            <Icon w="18%" as={<MaterialIcons name="location-pin"/>} size={6} ml="2"/>
            <Text w="82%" textAlign="left" fontWeight={500} fontSize={18}>
              {tender.state.name[localInfo.local]}
            </Text>
          </HStack>

        </HStack>

        {
          tender.latitude === null ? null : <View mt={6}>
            <Map
              isPreview={true}
              onPress={() => null}
              tenderCoordinate={{
                latitude: tender.latitude,
                longitude: tender.longitude
              }}
            />
          </View>
        }

        <Text textAlign="left" mt={4} fontSize={20}>
          {tender.description}
        </Text>

        <View flexDirection="row" my={2} mt={8}>
          {
            !!tender.closing_date ? (
              <View px={2} bg="appPrimary" borderRadius={20} alignSelf="flex-start">
                <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                  {translate('screens.tender.closingDate')} : {TimeFormatter.getFormattedDate(tender.closing_date)}
                </Text>
              </View>
            ) : null
          }
          <View ml={2} px={2} bg="appAccent" borderRadius={20} alignSelf="flex-start">
            <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
              {translate('screens.tender.offers')} { !!tender.offers_count ? tender.offers_count : 0 }
            </Text>
          </View>
        </View>


        {
          !!previewData ? (
            <>
              <View mt={12}/>
              <Divider thickness="2" />
              <View mt={12}/>
              <View
                flexDirection="row"
                justifyContent={"space-evenly"}
              >

                <Button
                  colorScheme="accent"
                  borderRadius={10}
                  shadow={3}
                  onPress={() => {
                    navigation.navigate('TenderApply', {tenderData:tender, previewData})
                  }}
                >
                  <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                    {translate('screens.tender.apply')} ({translate('screens.tender.preview')})
                  </Text>
                </Button>

                <Button
                  w={'30%'}
                  shadow={3}
                  borderRadius={10}
                  onPress={() => setShowConfirmModal(true)}
                >
                  <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                    {translate('misc.publish')}
                  </Text>
                </Button>

              </View>
            </>
          ) : null
        }
        {
          !!previewData || tender.is_owner || tender.has_participate
          || (!!tender.is_the_closing_date
            || tender.is_closed && tender.is_the_closing_date) ? null :
            <View mt={50}>
              <Button
                borderRadius={10}
                onPress={() => {
                  navigation.navigate('TenderApply', {tenderData:tender, previewData})
                }}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.apply')}
                </Text>
              </Button>
            </View>
        }
        {
          (!!tender.is_the_closing_date && tender.is_the_closing_date) || tender.is_closed ? (
            <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
                onPress={handleSubmitOffer}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.closed')}
                </Text>
              </Button>
            </View>
          ) : null
        }
        {/* Confirm Publish Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.tender.publishModal.headerText')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('screens.tender.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('screens.tender.publishModal.note')}
                  </Text>
                  {
                    showPublishError ? (
                      <Text mt={4} color="#B01116" textAlign={"center"} fontSize={17}>
                        {translate('misc.errorTryAgain')}
                      </Text>
                    ) : null
                  }
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handlePublishTender}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.publish')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Confirm offer Modal */}
        <Center>
          <Modal isOpen={showConfirmOfferModal} onClose={() => setShowConfirmOfferModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.tender.offerModal.headerText')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('screens.tender.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('screens.tender.offerModal.note')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmOfferModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.submit')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <View mt={48} />

      </ScrollView>
    </View>
  );
}

